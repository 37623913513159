import React from 'react';

const Icon = ({name}) => {
    return (
        <>
            {name === 'share' &&
                <svg
                    className="icon"
                    version="1.1"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g transform="translate(0 -1036.4)">
                        <path transform="matrix(.84528 0 0 .84528 33.943 1042.2)" d="m-22.411-3.3304a2.3661 2.3661 0 1 1-4.7321 0 2.3661 2.3661 0 1 1 4.7321 0z"/>
                        <path transform="matrix(.84528 0 0 .84528 33.943 1052.2)" d="m-22.411-3.3304a2.3661 2.3661 0 1 1-4.7321 0 2.3661 2.3661 0 1 1 4.7321 0z"/>
                        <path transform="matrix(.84528 0 0 .84528 23.943 1047.2)" d="m-22.411-3.3304a2.3661 2.3661 0 1 1-4.7321 0 2.3661 2.3661 0 1 1 4.7321 0z"/>
                        <path transform="translate(0 1036.4)" d="m12.777 2.5527-10.895 5.4473 10.895 5.4473 0.44531-0.89453-9.1055-4.5527 9.1055-4.5527z"/>
                    </g>
                </svg>
            }
            {name === 'star-tg' &&
                <svg
                    className="icon"
                    width="21"
                    height="20" viewBox="0 0 21 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M10.5197 16.2049L6.46899 18.6864C6.04779 18.9444 5.49716 18.8121 5.23913 18.3909C5.11311 18.1852 5.07554 17.9373 5.13494 17.7035L5.762 15.2354C5.98835 14.3444 6.59803 13.5997 7.42671 13.2018L11.8459 11.0801C12.0519 10.9812 12.1387 10.734 12.0398 10.528C11.9597 10.3611 11.7786 10.2677 11.5962 10.2993L6.67709 11.1509C5.67715 11.324 4.65172 11.0479 3.87392 10.3961L2.31994 9.09382C1.94135 8.77655 1.89164 8.21245 2.20891 7.83386C2.36321 7.64972 2.58511 7.53541 2.82462 7.51667L7.5725 7.1451C7.90793 7.11885 8.20025 6.90658 8.32901 6.59574L10.1607 2.17427C10.3497 1.71792 10.8729 1.50123 11.3292 1.69028C11.5484 1.78105 11.7225 1.95514 11.8132 2.17427L13.6449 6.59574C13.7736 6.90658 14.066 7.11885 14.4014 7.1451L19.1754 7.51871C19.6678 7.55725 20.0358 7.9877 19.9972 8.48015C19.9787 8.71704 19.8666 8.93682 19.6858 9.09098L16.0449 12.1949C15.7886 12.4134 15.6768 12.7574 15.7556 13.0849L16.8749 17.7348C16.9905 18.215 16.6949 18.698 16.2147 18.8137C15.9839 18.8692 15.7406 18.8307 15.5382 18.7068L11.4541 16.2049C11.1674 16.0292 10.8064 16.0292 10.5197 16.2049Z" />
                </svg>
            }
        </>
    );
};

export default Icon;