import React, {useState} from 'react';
import Modal from "../Modal";
import './BuyPredictionsModal.scss';
import {buyPredictions, buyPremium, getPredictionPackages} from "../../../api/cards";
import {useTelegram} from "../../../hooks/useTelegram";
import {getUserData} from "../../../api/user";
import {closeModal, setUser} from "../../../store/mainSlice";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../Loader/Loader";
import {useTranslation} from "react-i18next";
import Icon from "../../Icon/Icon";

const BuyPredictionsModal = () => {
    const { t } = useTranslation()
    const {tg} = useTelegram();
    const dispatch = useDispatch();

    const [activeName, setActiveName] = useState(null);
    const [isBuyBtnDisabled, setBuyBtnDisabled] = useState(false);
    const [packagesList, setPackagesList] = useState([]);

    const hasPremium = useSelector((state) => state.main.user.is_hq);

    const onBtnClick = (name) => {
        setActiveName(name === activeName ? null : name);
    }

    const buyPredictionsHandler = () => {
        if (!hasPremium) {
            setBuyBtnDisabled(true);

            if (typeof activeName === 'string') {
                buyPredictions(activeName)
                    .then(response => {
                        tg.openInvoice(response.result, onSuccessBuyMorePredictions);
                    })
                    .catch(e => console.log(e))
                    .finally(() => {
                        setBuyBtnDisabled(false);
                    });
            } else if (activeName === 'Unlimit') {
                buyPremium()
                    .then(response => {
                        tg.openInvoice(response.result, onSuccessBuyMorePredictions);
                    })
                    .catch(e => console.log(e))
                    .finally(() => {
                        setBuyBtnDisabled(false);
                    });
            }
        }
    }

    const getPredictionsCount = () => {
        return packagesList.find(el => el.name === activeName).amount;
    }

    const onSuccessBuyMorePredictions = (result) => {
        gtag("event", "successfull_payment");

        getUserData()
            .then(response => dispatch(setUser(response)))
            .catch((e) => console.log(e));

        dispatch(closeModal());
    }

    const onOpenHandler = () => {
        getPredictionPackages()
            .then(response => {
                setPackagesList(response);
            })
            .catch(e => console.log(e));
    }

    return (
        <Modal
            className="buyPredictionModal"
            name={'BuyPrediction'}
            header={
                <p className="modal__title">{t('buyMorePredictions')}</p>
            }
            onOpen={onOpenHandler}
        >
            <p className="buyPredictionModal__description">{t('howManyPredictionsBuy')}</p>

            <div className="buyPredictionModal__list">
                {packagesList?.length ?
                    packagesList.map(elem =>
                        elem.name === 'Unlimit' ?
                            <div
                                className={
                                    'premium buyPredictionModal__btn' +
                                    (activeName === 'Unlimit' ? ' active' : '')
                                }
                                key={elem.amount}
                                onClick={() => onBtnClick(elem.name)}
                            >
                                <div className="buyPredictionModal__btnInner">
                                    <p className="buyPredictionModal__btnCount">{t('unlimitedPredictions')}</p>

                                    <p className="buyPredictionModal__btnStars"><span><Icon
                                        name={'star-tg'}/>{elem.price}</span></p>
                                </div>
                            </div>
                            :
                            <div
                                className={
                                    'buyPredictionModal__btn' +
                                    (elem.name === activeName ? ' active' : '') +
                                    (elem.amount === 50 ? ' popular' : '')
                                }
                                style={{order: elem.amount}}
                                key={elem.amount}
                                onClick={() => onBtnClick(elem.name)}
                            >
                                <div className="buyPredictionModal__btnInner">
                                    {elem.amount === 50 &&
                                        <span className="buyPredictionModal__btnPopularBadge">Popular</span>
                                    }

                                    <p className="buyPredictionModal__btnCount">
                                        <span>{elem.amount}</span><br/> {t('predictions')}
                                    </p>

                                    <p className="buyPredictionModal__btnStars"><span><Icon
                                        name={'star-tg'}/>{elem.price}</span></p>
                                </div>
                            </div>
                    )
                    :
                    <Loader/>
                }
            </div>

            <div className="buyPredictionModal__bottom">
                <button
                    className="button"
                    disabled={!activeName || isBuyBtnDisabled}
                    onClick={buyPredictionsHandler}
                >{isBuyBtnDisabled ?
                    <Loader className="dark"/>
                    :
                    activeName ?
                        activeName === 'Unlimit' ?
                            <>{t('buyUnlimitedPredictions')}</>
                            :
                            <>{t('buyPredictionsCount', [getPredictionsCount()])}</>
                        :
                        <>{t('buyMorePredictions')}</>
                }</button>
            </div>
        </Modal>
    );
};

export default BuyPredictionsModal;