import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setLanguage, setUser, showModal} from "../../store/mainSlice";
import './SettingsPage.scss';
import {useTranslation} from "react-i18next";
import {useTelegram} from "../../hooks/useTelegram";
import {getUserData} from "../../api/user";

const SettingsPage = () => {
    const { t } = useTranslation()
    const {tg} = useTelegram();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.main.user);
    const refLink = useSelector((state) => state.main.refLink);
    const langList = [
        { code: 'ru', text: 'Русский' },
        { code: 'en', text: 'English' }
    ];
    const [currentLanguage, setCurrentLanguage] = useState('');

    const setNewLanguage = useCallback(() => {
        dispatch(setLanguage(user?.language_code === 'ru' ? 'en' : 'ru'));

        setTimeout(() => updateUserData(), 100);
    }, [user.language_code, dispatch]);

    const getCurrentLanguage = () => {
        let text = langList.find(l => l.code === currentLanguage)?.text;

        if (!text) {
            text = langList.find(l => l.code === 'en')?.text;
        }

        return text;
    }

    const buyMorePredictions = () => {
        gtag("event", "start_payment");

        dispatch(showModal({modalName: 'BuyPrediction'}));
    }

    const updateUserData = () => {
        getUserData()
            .then(response => {
                dispatch(setUser(response));
            })
            .catch((e) => console.log(e));
    }

    const share = () => {
        const appLink = refLink ? refLink : 'https://t.me/tarotalks_bot/Tarotalks';
        const text = t('textForShareMain');

        gtag("event", "share_application_settings");

        tg.openTelegramLink(`https://t.me/share/url?url=${appLink}&text=${text}`);
    }

    useEffect(() => {
        if (user?.language_code) {
            setCurrentLanguage(user.language_code)
        }
    }, [user]);

    return (
        <div className="settingsPage page">
            <div className="settingsPage__element">
                { t('textLanguage') }

                <span
                    className="settingsPage__lang"
                    onClick={ () => setNewLanguage() }
                >{ getCurrentLanguage() }</span>
            </div>

            {(typeof user?.count_predictions === 'number' || user?.count_predictions === '♾️') &&
                <div className="settingsMorePredictions settingsPage__element">
                    {t('predictionsAvailable', [user.count_predictions])}

                    {(!user?.is_hq && user?.count_predictions !== '♾️') &&
                        <button
                            className="button"
                            onClick={buyMorePredictions}
                        >{t('buyMore')}</button>
                    }
                </div>
            }

            <div className="settingsShare">
                <div className="settingsShare__inner settingsPage__element">
                    <div className="settingsShare__text">
                        <p className="settingsShare__textTitle">{t('shareApp')}</p>

                    </div>

                    <button
                        className="button"
                        onClick={share}
                    >{t('share')}</button>
                </div>

                {refLink &&
                    <p className="muted">{t('shareFreePredictionsText')}</p>
                }
            </div>

            <div className="settingsDivider"/>

            <div className="settingsTG">
                <p>{t('settingsTgGroup')}</p>

                <p><a href="https://t.me/tarotalks_channel" target="_blank">https://t.me/tarotalks_channel</a></p>
            </div>
        </div>
    );
};

export default SettingsPage;