import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {closeModal as closeModalAction} from "../../store/mainSlice";
import './Modal.scss';

const Modal = ({ name, header, footer, children, className = '' , onOpen}) => {
    const dispatch = useDispatch();

    const [isOpened, setOpened] = useState(false);

    const closeModal = () => {
        setOpened(false);

        setTimeout(() => {
            dispatch(closeModalAction());
        }, 300);
    }

    const closeModalBefore = () => {
        closeModal();
    }

    useEffect(() => {
        setOpened(true);

        onOpen();

        return () => setOpened(false);
    }, []);

    return (
        <div className={'modal' + (isOpened ? ' opened' : '') + (className ? ' ' + className : '')}>
            <div className="modal__outer">
                <div
                    className="modal__before"
                    onClick={closeModalBefore}
                />

                <div className="modal__content">
                    <div className="modal__head">
                        {header}

                        <button
                            type="button"
                            className="modal__close modal__close-handler icon icon-close"
                            onClick={closeModal}
                        >&times;</button>
                    </div>

                    <div className="modal__body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;